var exports = {};

exports = function (obj) {
  if (!obj || typeof obj !== "object") return obj;
  var copy;

  if (isArray(obj)) {
    var len = obj.length;
    copy = Array(len);

    for (var i = 0; i < len; i++) {
      copy[i] = obj[i];
    }
  } else {
    var keys = objectKeys(obj);
    copy = {};

    for (var i = 0, l = keys.length; i < l; i++) {
      var key = keys[i];
      copy[key] = obj[key];
    }
  }

  return copy;
};

var objectKeys = Object.keys || function (obj) {
  var keys = [];

  for (var key in obj) {
    if ({}.hasOwnProperty.call(obj, key)) keys.push(key);
  }

  return keys;
};

var isArray = Array.isArray || function (xs) {
  return {}.toString.call(xs) === "[object Array]";
};

export default exports;